.ProductsGeneralInfoLocationView {
  :global {
    .productsGeneralInfoLocationView-content {
      .titleGeneralInfo {
        margin-right: 10px;
        align-items: center;
        height: 32px;

        .containerZoom {
          background-position: right;
        }
      }    

      .NoData-box {
        margin: auto;
        top: 34%;
        position: relative;
      }

      .dropzone {
        width: 100%;
        height: 100%;
      }

      .prodChildNoZoom {
        .rowChildInfo {
          .row {
            justify-content: center;

            .box-row {
              position: relative;
              margin: 12px;

              .inside-box-row {
                width: 300px;
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
}
