

:root {
    --warnings-bg-color: #FFB23E;
    --alarms-bg-color: #ff6d4e;
    --rules-bg-color: #cdc4ff;
    --alerts-white-color: white;
    --alerts-black-color: black;
}


.container.alerts .primary {
    height: 90px;
}

.primary-parent {
    min-width: 280px;
}

.container.alerts .primary:last-child {
    margin-right: 0px;
}

.alarm {
    background-color: var(--alarms-bg-color);
    color: var(--alerts-white-color);
}

.warning {
    background-color: var(--warnings-bg-color);
    color: var(--alerts-black-color);
}

.rule {
    background-color: var(--rules-bg-color);
    color: var(--alerts-black-color);
}

.primary .row {
    height: 100%;
}

.separator { /* Now a pseudo element */
    position: relative;
}

.separator .line {
    position: absolute;
    height: 50px;
    border-right: 1px solid black;
    right: 0;
    top: 50%;
    transform: translate(0,-50%);
}

.alerts .white, .alerts .white h3, .alerts .white h6 {
    color: white;
}
.alerts h3, .alerts h6 {
    text-transform: capitalize;
}
.alerts h3 {
    padding: 2px 0px;
}
.alerts .apl-5 {
    padding-left: 5px;
}

.alerts .t-number.big {
    font-size: 30px;
    line-height: 25px;
}
.table-header{
    padding-left: 12px;
}
.alerts .t-number {
    font-weight: bold;
    font-size: 15px;
    line-height: 10px;
}

.alerts img {
    border-radius: 100%;
    max-width: 40px;
    
}
.alerts img.no-img {
    filter: brightness(0);
}
.white .separator .line {
    border-right: 1px solid white;
}

.btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}
.react-bootstrap-table-pagination {
    font-size: 0.7rem;
}
button#pageDropDown {
    font-size: 0.7rem;
}

.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 1px !important;
}
.table .form-check {
    padding-left:4px;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6 !important;
}

.custom-button {
    font-size: 13px;
    padding: 5px 7px;
}
.btn.custom-button{
    border-radius:3px !important;
}
span.custom-button-span {

    padding: 2px 5px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 0.7rem;
}
.separator-small {
    border-left: 1px solid #d5d5d5;
    height: 14px;
    display: inline-block
}


@media (max-width: 992px) {
    .container-scroll {
        overflow-x: auto;
    }

        .container-scroll > .row {
            width: 140.33333333%; /* = 100% * 4/3 */
        }
}

@media (max-width:770px) {
    .container-scroll {
        overflow: none;
    }

        .container-scroll > .row {
            width: 100%; /* = 100% * 4/3 */
        }
}

