$border-testing: 1px solid red;

.userProfileView {
  :global {
    .container-userProfileView {
      // border: $border-testing;

      .userInfo {
        // margin: 30px -15px;

        .sp {
          padding: 0;
        }

        .text-role {
          p {
            margin: 0;
          }

          .guid {
            letter-spacing: 0px;
            font-size: 11px;
            // font-style: italic;
          }
        }

        .userAvatarContainer {
          width: 100%;
          position: relative;
          max-width: 200px;
          margin: 0 auto;
          margin-top: 15px;
          margin-bottom: 15px;
          > div {
            position: relative;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            max-width: 200px;

            // > img {
            //   // Estilos para la imagen del avatar
            // }
          }
        }
      }

      .disabled-mode {
        background-color: #80808014 !important;
        filter: invert(1) !important;
        border-color: transparent !important;
        cursor: not-allowed !important;
      }

      .MuiAvatar-root.MuiAvatar-circle.MuiAvatar-colorDefault {
        position: relative;
        width: 100%;
        padding-bottom: 50%;
        padding-top: 50%;
        height: 0;
        max-width: 200px;

        &.MuiAvatar-circle {
          position: relative;
          width: 100%;
          height: auto;
          aspect-ratio: 1/1;
        }
      }

      .warningText {
        margin: 24px 0;
        padding: 4px 24px;
        border-left: 5px solid #ffe564;
        background-color: rgba(255, 229, 100, 0.2);

        p {
          margin-top: 16px;
        }
      }

      .form-check {
        padding: 0;
        label {
          font-size: initial;

          &::after {
            top: 8px;
            left: 4px;
          }
        }
      }

      .roles {
        border-top: 1px solid #d5d5d5;

        .container-roles {
          padding: 30px 0;
          max-width: 850px;
        }

        .grid-2_1 {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 4rem;
          @media (max-width: 1199px) {
            grid-template-columns: 1fr;
          }
        }

        .type {
          p {
            margin: 0;
          }
        }

        .list-roles {
          margin-top: 25px;
          .list {
            row-gap: 0.2rem;
          }
        }

        .all-factories {
          margin-top: 25px;

          .select-factories {
            width: 100%;
            .select-btns {
              margin: 25px 0;
              display: inline-flex;
              width: 100%;
              gap: 25px;
              .inside-box {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                width: 100%;

                .react-select-container {
                  max-width: 450px;
                }

                .title {
                  text-align: left;
                  font-weight: 600;
                  margin-top: 0;
                }
                .MuiSelect-select {
                  padding: 10px;
                }
              }
            }
          }

          .btn-add-center {
            margin-left: 0;
            margin-top: 25px;
          }

          .factories-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 4rem;
            row-gap: 1rem;

            @media (max-width: 768px) {
              grid-template-columns: 1fr;
            }

            .dropdown-center {
              button {
                padding: 0;
              }
              .dropdown-toggle::after {
                display: none;
              }
            }
            .center-factory-box {
              // margin-top: 35px;
              display: inline-flex;
              width: 100%;
              gap: 7%;
              justify-content: flex-start;

              .assigned-box {
                // position: relative;
                box-shadow: 2px 2px 10px #ededed;
                box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
                padding: 15px;
                // padding: 15px 15px 10px;
                border: 1px solid #c4c4c430;
                // max-width: 300px;
                width: 100%;
                display: flex;
                align-items: center;
                align-content: flex-start;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 5px;

                .dropdown-menu {
                  max-width: 150px;

                  .userMenuItem {
                    font-weight: 300;
                    cursor: pointer;
                    padding: 0 15px !important;
                    display: flex;
                    align-items: center;
                  }
                }

                .name-box {
                  display: inline-flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  padding-bottom: 5px;
                  font-weight: 600;
                }

                .box-dots {
                  img {
                    padding: 5px;
                    border: 1px solid #7c7c7c;
                    border-radius: 3px;
                    width: 30px;
                    transition: 0.2s linear all;

                    &:hover,
                    :active {
                      background-color: #7c7c7c;
                      cursor: pointer;
                      filter: invert(1);
                    }
                  }
                }

                // .edit-parent {
                //   position: absolute;
                //   right: 5px;
                //   top: 5px;
                // }

                .factory-box {
                  display: inline-flex;
                  justify-content: space-between;
                  align-content: center;
                  align-items: center;
                  width: 100%;
                  border-top: 1px solid #f4f4f4;

                  .title {
                    padding-top: 10px;
                  }
                  .remove-btn {
                    button {
                      min-width: 20px;
                      max-width: 20px;
                      max-height: 20px;
                      padding: 0 !important;
                      width: 60px !important;
                      border-radius: 100px !important;
                      margin: 0 !important;

                      &:hover {
                        img {
                          filter: invert(1);
                        }
                      }
                    }
                  }
                }

                p {
                  font-size: 15px;
                  margin: 0;
                  line-height: 25px;
                  text-align: left;
                  // &:first-of-type {
                  //   padding-bottom: 7px;
                  // }
                }
              }
            }
          }
        }

        h2 {
          padding-bottom: 15px;
        }
      }
    }
  }
}
