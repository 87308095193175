.HWPackageView {
  :global {
    .HWPackageView-content {
      .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.Toolbar-toolbar {
        z-index: 999;
      }

      .components-box{
        .nosmart-compos{
          margin-top:15px;
        }
      }

      .edit-button {

        &.disabled-mode{
          color: #c3c3c3;
          pointer-events: none;
        }
        width: 24px;
        margin-right: 5px;
        cursor: pointer;
        float: left;
      }
    }
  }
}