.EmptyLastValueChartView {
  :global {
    .container-EmptyLastValueChartView {
      filter: grayscale(1);
      .prodChildNoZoom {
        background-color: #fbfbfb;
        .icon {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
