.Installations {
    :global {
        .siteImage {
            width: 100%
        }
        
        .siteCardImg {
            position: relative;
            width: 100%;
            height: 0;
            padding: 0;
            padding-bottom: 100%;
            margin: 0;
            background-size: auto 100%; /* cover all space in the card */
            /*background-size: contain;*/ /* show full image with white margins */
            background-repeat: no-repeat;
            background-position: center;
        }
        
        .arrow {
            float: left;
            letter-spacing: -4px;
            font-family: monospace;
            float: right;
            margin-top: 9px;
            margin-right: 10px;
            transition: margin 150ms ease-in-out;
        }
        
        /*FIN InstallationList*/
        .detalleProducto {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.25s linear;
            background: white;
            border-radius: 5px;
            padding: 10px;
            height: 270px;
            width: 250px;
            overflow: hidden;
            z-index: 98;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        }
        
        .detalleProducto::after {
            content: '';
            width: 25px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background: linear-gradient( 90deg,transparent 0%, white 100%);
            z-index: 99;
        }
        
        .productDetailShow {
            visibility: visible;
            opacity: 1;
        }
        
        .unplacedLabel {
            position: absolute;
            top: 365px;
            padding-left: 10px;
        }
        
        .SummaryValue {
            font-family: 'AktivGrotesk-light',sans-serif !important;
            font-size: 24px;
            letter-spacing: normal;
            text-align: center;
        }
        
        .prodStateMap {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 5px;
            right: 0;
            display: flex;
        }
        
        /* can be changed but must maintain 5/(3+100px) relation */
        /* CHANGE ALSO AT InstallationsManagement.js 138,139 */
        .canvasSize {
        /*    width: 500px;
            height: 400px;*/
        }
        
        #inner-dropzone {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top center;
        }
        
        .centerObj {
            margin-left: -30px !important;
            margin-top: -30px !important;
        }
        
        .canvasLayoutConfig {
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        }
        
        .layoutConfigButtonsParent {
            float: right;
        }
        
        .layoutConfigButtons {
            margin: auto 10px;
        }
        
        .managmentBtnClass {
            background-color: transparent;
            border-color: transparent;
            font-family: 'roboto-bold',Helvetica,Arial,sans-serif;
            text-transform: uppercase;
            font-size: 12px;
        }
        
        .prodInstallationManagment {
            width: 30px;
            height: 30px;
            display: flex;
        }
        
        .not-drag-drop .prodInstallationManagment, .can-drop .prodInstallationManagment, #drop .prodInstallationManagment {
            margin: -10px 0 0 -10px;
        }
        
        .containerNotActiveItems {
            position: absolute;
        }
        
        .not-drag-drop {
            padding: 2px;
            margin: 10px;
            touch-action: none;
            user-select: none;
            transform: translate(0px, 0px);
        }
        
        .hoverInfoHidden {
            display: none;
        }
        
        .hoverInfoShow {
            background-color: #EDEDED;
            border: 0.5px solid black;
            border-radius: 5px;
            padding: 5px;
            color: black;
            font-family: 'roboto-light',Helvetica,Arial,sans-serif;
            font-size: 11px;
            display: flex !important;
            position: absolute;
            bottom: -30px;
            right: 40px;
            z-index: 100;
        }
        
        .sinpadding {
            padding: 0px;
        }
        
        .testSnack {
            position: fixed;
            width: 200px;
            height: 20px;
            bottom: 20px;
            left: 20px;
            background: red;
        }
        
        .oneLine {
            margin-top: -2px !important;
            text-overflow: clip;
            overflow: hidden;
            white-space: nowrap;
        }
        
        .not-drag-drop.can-drop {
            cursor: pointer
        }
        
        .toggleLayoutBtn {
            width: 48px;
            height: 48px;
            margin-top: 7px !important;
        }
        
        .prodInfo {
            display: flex;
            align-items: center;
        }
        
        .summaryInfo h5 {
            text-transform: none;
        }
        
        .rs-table-treetable {
            width: 100% !important;
        }
        
        .rs-table-scrollbar-horizontal {
            visibility: hidden;
        }
        
        .installationsReportingCharts {
            padding-left: 35px;
        }
        
        .radioButtonReporting {
            margin-left: 10px !important;
            margin: 0px;
        }
        
        div.menuRaportng {
            border-right: 0.1px solid lightgray;
        }
        
        span.titleMenuRaporting svg {
            float: right;
        }
        
        span.titleMenuRaporting {
            color: black;
            font-weight: bold;
        }
        
        span.titleMenuRaporting.colorLight {
            font-weight: normal;
        }
        
        span.titleMenuRaporting.colorLight svg {
            color: #6f6f6f;
        }
        
        span .noItemMenuRaporting {
            margin-top: 10px;
            color: #999;
        }
        
        div.subGroupMenuRaporting {
            margin-top: 30px;
        }
        
        div.subGroupMenuRaporting:first-child {
            margin-top: 0px;
        }
        
        div.subGroupMenuRaporting .flexContainer {
            flex-wrap: nowrap !important;
        }
        
        
        .kpiIcon {
            height: 45px;
            padding-bottom: 5px;
        }
        
        .kpiValue {
            font-size: 30px;
            letter-spacing: normal;
            text-align: center;
            color: #000000;
        }
        
        .kpiValueAc {
            letter-spacing: normal;
            text-transform: uppercase;
            text-align: center;
        }
        
        .ruleModal {
            /*width: 90%;*/
            /*max-width: 90% !important;
            min-width: 70%;*/
            max-width: 800px !important;
            width: 90%;
            min-width: 400px;
        }
        
        .ruleModal .imgDialog {
            width: 100%;
            height: auto;
        }
        
        .installationModal {
            width: 80%;
            max-width: 80% !important;
        }
        
        body {
            margin: 0;
        }
        
        .table-bordered {
            border: none;
        }
        
        .table-bordered:focus {
            outline: none;
        }
        
        .table-bordered th, .table-bordered td {
            border: none;
        }
        
        .gateways .product-card__wrapper::after {
            content: '';
            width: 25px;
            height: 100%;
            position: absolute;
            right: 20px;
            top: 0;
            background: linear-gradient( 90deg,transparent 0%, white 100%);
            z-index: 99;
        }
        
        .gateways .product-card__wrapper {
            min-height: 357px;
        }
        
        .edit-site button:hover img {
            transition: all .2s ease-in 0s;
            filter: invert(1);
        }
        
        .edit-site button {
            min-width: 40px !important;
            min-height: 40px !important;
            max-width: 40px !important;
            max-height: 40px !important;
            padding: 0 !important;
            width: 60px !important;
            border-radius: 100px !important;
            margin: 0 !important;
        }
        
        .edit-site {
            z-index: 90;
            position: absolute;
            top: -7px;
            right: 0;
        }
        
        .parent-chart{
            display:flex;
            flex-wrap:wrap;
        }
        .parent-echarts {
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            grid-template-rows: auto;
            grid-template-areas:
                "pie pie pie pie"
                "... ... ... ...";
            width: 100%;
        }
            .parent-echarts .pie {
                grid-area: pie;
              
            }
            .parent-echarts .gauge {
               height:150px;
                padding: 20px;
            }
        
        .legend {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 20px;
            margin-bottom:40px;
        }
            .legend.sticky {
                position: sticky;
                top: -8px;
                background-color: white;
                z-index: 1;
            }
            .legend .child {
                padding-right: 15px;
                display: flex;
                cursor: pointer;
                padding-bottom: 5px;
            }
            .legend .color {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                align-self: center;
            }
            .legend .child span {
                padding-left: 10px;
                margin-bottom: 0;
                align-self: center;
            }
            .legend .child.notselected, .legend .child.notselected span{
                filter: grayscale(1);
                color: lightgray!important;
            }
        .parent-echarts .line_echart, .parent-echarts .fullWidth {
            width: 100%;
            grid-column-start: 1;
            grid-column-end: -1;
        }
        .gauge.notselected{
            display:none;
        }
        
        .parent-echarts.s1 {
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            grid-template-rows: auto;
            grid-template-areas:
                "pie pie pie ..."
                "pie pie pie ...";
            width: 100%;
        }
        
        .parent-echarts.s2 {
            display: grid;
            grid-template-columns: 33% 33% 33%;
            grid-template-rows: auto;
            grid-template-areas:
                "pie pie pie"
                "pie pie pie";
            width: 100%;
        }
        
        .parent-echarts.s3 {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: auto;
            grid-template-areas:
                "pie pie"
                "pie pie";
            width: 100%;
        }
        .title {
            margin-top: 30px;
            margin-bottom: 0px;
            text-align: center;
        }
    }
}