.managementView {
  :global {
    .container-managementView {
      // border: 1px solid #d3d3d3;

      .react-swipeable-view-container{
        min-height: 60px;
      }

      .MuiBox-root{
        padding:0;
      }
    }
  }
}