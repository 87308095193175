.AzureConnect {
  :global {
    .environment {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #596cf9;
      color: white;
      padding-left: 5px;
      border-bottom-right-radius: 20px;
      z-index: 9999;
      width: 50px;
      font-weight: bold;

      &.bg-test {
        background-color: #b90202;
      }
      &.bg-local {
        background-color: #02b921;
      }
    }
  }
}
