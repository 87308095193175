.ModalInstallationLocationZoomView {
  :global {
    .modalInstallationLocationZoomView-content {
      .insideModal {
        border-bottom: 1px solid rgb(164, 164, 164);
        padding-bottom: 20px;
        margin-bottom: 15px;
      }

      .notActive {
        display: inline-flex;
        justify-content: flex-start;
        width: 100%;
        #drop {
          transform: scale(1.5);
          padding: 4px;
          .prodInstallationManagment {
            display: contents;
          }
        }
      }

      .modalZoomLocation {
        width: 300px;
        height: 100%;

        .noData-box {
          margin: auto;
          top: 34%;
          position: relative;
        }

        .col {
          padding: 0;
          .ZoomProdModalChild {
            justify-content: center;
            display: flex;

            .box-modal {
              position: relative;
              margin: 12px;

              .inbox-modal {
                width: 300px;
                height: 200px;

                .dropzone {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
