.userView {
  :global {
    .container-userView {
      .MuiBox-root {
        padding-right: 0;
        padding-left: 0;
      }

      .go-back {
        margin: 15px 0 -15px 9px;

        button {
          width: 100px;
          height: 30px;
          display: inline-flex;
          justify-content: center;
          padding: 7px 7px 3px;
          line-height: initial;
          align-items: center;
          min-width: auto;
          min-height: auto;
        }
      }

      .react-swipeable-view-container {
        > div {
          overflow: hidden !important;
        }
      }
      .text {
        text-transform: capitalize;
        &.personal {
          background-color: #00ff0852;
        }

        &.manage {
          background-color: #ffc80052;
        }
      }
    }
  }
}