.ProductsGeneralSkeletonView {
  :global {
    margin-top: 10px;
    position: relative;
    .productsGeneralSkeletonView-content {
      position: relative;

      &.dev {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        // span {
        //   background-color: rgba(253, 0, 0, 0.38) !important;
        // }
      }

      .content-box {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 26px;

        .installation-map {
          width: 33%;
          display: flex;
          row-gap: 23px;
          flex-direction: column;

          .lastValues-box {
            display: flex;
            flex-direction: column;

            .lastValues {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              gap: 25px;
              margin-top: 35px;
              margin-right: 15px;
            }
          }
        }

        .statistics {
          width: 62.5%;
          display: flex;
          flex-direction: column;
          gap: 18px;

          .calendar-content{
            margin-bottom: 4px;
          }

         
        }
      }
    }
  }
}
