.userFactoriesView {
  width: 80%;
  :global {
    .select-btns {
      margin: 25px 0;
      display: inline-flex;
      width: 100%;
      gap: 25px;
      .inside-box {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;

        .react-select-container {
          max-width: 450px;
        }

        .title {
          text-align: left;
          font-weight: 600;
          margin-top: 0;
        }
        .MuiSelect-select {
          padding: 10px;
        }
      }
    }

    .title-modal {
      margin-left: 1rem;
    }

    .MuiDialogContent-root {
      padding-top: 0 !important;
    }

    .center-factory-box {
      // margin-top: 35px;
      display: inline-flex;
      width: 100%;
      gap: 7%;
      justify-content: flex-start;

      .assigned-box {
        display: flex;
        align-items: center;
        align-content: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5px;

        .edit-btn{
          margin-left:0;
        }

        .dropdown-menu {
          max-width: 150px;

          .userMenuItem {
            font-weight: 300;
            cursor: pointer;
            padding: 0 15px !important;
            display: flex;
            align-items: center;
          }
        }

        .name-box {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-bottom: 5px;
          font-weight: 600;
        }

        .box-dots {
          img {
            padding: 5px;
            border: 1px solid #7c7c7c;
            border-radius: 3px;
            width: 30px;
            transition: 0.2s linear all;

            &:hover,
            :active {
              background-color: #7c7c7c;
              cursor: pointer;
              filter: invert(1);
            }
          }
        }

        // .edit-parent {
        //   position: absolute;
        //   right: 5px;
        //   top: 5px;
        // }

        .factory-box {
          display: inline-flex;
          justify-content: space-between;
          align-content: center;
          align-items: center;
          width: 100%;
          border-top: 1px solid #f4f4f4;
          padding: 10px 0;

          &:first-of-type {
            border-top: none;
            padding-top: 0;
          }

          .form-check {
            padding-left: 0 !important;
            position: relative;
            .form-check-input {
              position: absolute;
              width: 100%;
              height: 100%;
              z-index: 999;
              cursor: pointer;          
            }

            label {
              font-size: 15px;
              &::after {
                top: 7px !important;
                left: 4px !important;
              }
            }
          }

          .title {
            padding-top: 10px;
          }
          .remove-btn {
            button {
              min-width: 20px;
              max-width: 20px;
              max-height: 20px;
              padding: 0 !important;
              width: 60px !important;
              border-radius: 100px !important;
              margin: 0 !important;

              &:hover {
                img {
                  filter: invert(1);
                }
              }
            }
          }
        }

        p {
          font-size: 15px;
          margin: 0;
          line-height: 25px;
          text-align: left;
          // &:first-of-type {
          //   padding-bottom: 7px;
          // }
        }
      }
    }
  }
}