
.siteImage {
    width:100%
}

/*.siteCard {
    min-height: 500px;
}*/

.siteCardImg {
    position: relative;
    width: 100%;
    height: 0;
    padding: 0;
    padding-bottom: 100%;
    margin: 0;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.arrow {
    float: left;
    letter-spacing: -4px;
    font-family: monospace;
    float: right;
    margin-top: 9px;
    margin-right: 10px;
    transition: margin 150ms ease-in-out;
}


button.primary.add-sites {
    margin: 25px auto 30px auto;
}

@media (max-width: 992px) {
    button.primary.add-sites {
        margin: 30px 0 30px auto !important;
    }
}

.notificationAlarms{
    display:flex;
}
.notificationAlarms div {
    padding: 0px 5px;
    font-size: 12px;
    height: 18px;
    border-radius: 100%;
    line-height: 18px;
    margin-right: 5px;
}

@keyframes geoIconAnimation {
    from {
        transform: scale(1) translateY(0);
    }

    to {
        transform: scale(2) translateY(-5px);
    }
}

img.geoIcon.activeAnimation {
    animation-name: geoIconAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.zIndexMax {
    z-index: 99;
}