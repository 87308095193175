.KPISChart {
  width: 100%;
  :global {
    .KPISChart-container {
      margin-bottom: 25px;
      margin-top: -12px;
      width: 100%;
      background-color: #fff;
      &.lastValue-group {
        display: inline-flex;
        gap: 25px;
        justify-content: center;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
      }

      &.without-border {
        .chart-group {
          box-shadow: none;
        }
      }

      .lastValueBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 25px;
        margin-top: 20px;
      }

      .chart-group {
        box-shadow: 2px 2px 10px #ededed;

        .bar-chart-box {
          margin-bottom: 25px;
          width: 100%;
        }

        &.lastValueGroup {
          box-shadow: none;

          .charts-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 25px;
            // justify-content: space-between;
          }
        }

        &.general {
          > .header-chart-group {
            margin: 0;
            margin-top: 0px;
            position: sticky;
            top: 134px;
            z-index: 999 !important;
            margin-left: -49px !important;
            margin-right: -49px !important;

            > .general-site-legend {
              background-color: #fbfbfb;
            }
          }

          .general-site-legend {
            flex-wrap: nowrap;
            overflow-x: auto;
            scrollbar-width: thin;
            scrollbar-color: #ccc #f0f0f0;
            padding: 5px 15px;
            margin-top: 0px;
            position: sticky;
            top: 134px;
            z-index: 999;
            border-bottom: 1px solid #f7f7f7;
            display: inline-flex;
            gap: 15px;
            align-items: center;
            align-content: center;
            justify-content: center;
            width: 100%;
            background-color: white;
            &.desb {
              justify-content: flex-start;
            }
          }         
        }

        .header-chart-group {
          &.empty {
            background-color: #fbfbfb;
          }
          h4 {
            padding-top: 25px;
            padding-bottom: 10px;
          }

          .new-legend {
            border: 0;
            display: inline-flex;
            gap: 15px;
            align-items: center;
            align-content: center;
            justify-content: center;
            width: 100%;
            padding-bottom: 5px;
            background-color: white;
            flex-wrap: wrap;
          }

          .child {
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            align-content: center;
            gap: 5px;
            transition: all 0.2s ease-in 0s;

            &.notselected {
              filter: grayscale(1);
              span {
                color: #d3d3d3 !important;
              }

              .color {
                background-color: #dbdbdb !important;
              }
            }

            &:hover {
              span {
                filter: grayscale(1);
                color: #d3d3d3 !important;
              }

              .color {
                background-color: #dbdbdb !important;
              }
            }
            .color {
              align-self: center;
              border-radius: 100%;
              height: 10px;
              width: 10px;
            }

            span {
              padding-top: 3px;
              margin-bottom: 0;
            }
          }
        }
      }

      .charts-box {
        .gauges-chart-box {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }

        .sankey-chart-box {
          display: grid;
          width: 100%;
        }
      }
    }
  }
}
