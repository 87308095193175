.InstallationsGeneralView {
  :global {
    .installationsGeneralView-content {
      .mapZoomIcon {
        margin-right: 5px;
        font-size: 25px;
        cursor: pointer;
      }
    }
  }
}
