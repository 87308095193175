
/* GeneralNavMenu Styles, specific Laufen/Roca styles can be found on src/assets/styles */

.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none !important;
}

.dropdown-menu {
    top: -6% !important;
    z-index: 1000;
    width: 250px;
    background-color: #fff;
    border-radius: 5px !important;
    box-shadow: 2px 2px 10px #00000088;
    padding: 0 !important;
    border: none !important;
}

    .dropdown-menu a.langItemMenu {
        padding: 5px 0 5px 15px;
        margin-bottom: 0;
        border-top: 1px solid #e4e4e4;
        transition: padding 100ms ease-in-out;
    }

        .dropdown-menu a.langItemMenu:hover {
            padding-left: 20px;
        }

/*Megamenu*/
body {
    color: #000;
    background: #fff;
}


#wrapper {
    width: 100%;
    min-height: 600px;
    margin: 0 auto;
}

.MegamenuNav {
    position: relative;
    width: 100%;
    height: 70px;
    margin: 0 auto;
}

.espaciador {
    flex: 1 1 auto;
}

header, .MegamenuNav {
    display: block;
}

.topNavBar {
    padding-top: 5px;
}

.headerSpace {
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
}

.MegamenuNav ul#menu {
    display: block;
    margin: 0;
    padding: 0 0 0 20px;
}

    .MegamenuNav ul#menu li {
        position: relative;
        display: inline-block;
        z-index: +1;
    }


.mega {
    position: absolute;
    top: 130px;
    left: 0px;
    height: auto;
    background: #ffffff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    width: 100%;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.25s linear;
    min-height: 318px;
    z-index: 99;
}

    .mega > div.row {
        margin: 0 -15px;
        margin-right: 0;
    }

.megaListProduct {
    padding-left: 30px;
}

.display-menu {
    visibility: visible;
    opacity: 1;
}


ul#menu ul {
    float: left;
    width: 23%;
    margin: 0 2% 15px 0;
    padding: 0;
    list-style: none;
}

    ul#menu ul li {
        display: block;
    }

        ul#menu ul li a {
            float: left;
            display: block;
            width: 100%;
            height: auto;
            line-height: 1.3em;
            color: #888;
            text-decoration: none;
            padding: 6px 0;
        }

        ul#menu ul li:first-child a {
            font-size: 1.2em;
            color: #8dc63f;
        }

        ul#menu ul li a:hover {
            color: #fff;
            background: none;
        }

        ul#menu ul li:first-child a:hover {
            color: #fff;
        }

.imageMenu {
    width: 100%;
    margin-bottom: 15px;
}

.header-top {
    min-height: 264px;
    height: calc(100% - 72px)
}

.header-footer {
    width: 100%;
    border-top: 1px solid #e0e0e0;
    padding: 0 30px;
    margin: 0 -15px;
}


    .header-footer > div {
        padding-top: 28px;
        padding-bottom: 25px;
        border-left: 1px solid #e0e0e0;
    }

        .header-footer > div a, .header-footer > div a:hover {
            text-decoration: none;
        }

        .header-footer > div li {
            display: flex !important;
        }

        .header-footer > div img {
            margin-left: 5px;
        }

        .header-footer > div span {
            width: auto;
        }

            .header-footer > div span::after {
                display: block;
                content: '';
                border-bottom: solid 1px #7c7c7c;
                transform: scaleX(0);
                transition: transform 200ms ease-in-out;
                transform-origin: 0% 50%;
            }

        .header-footer > div li:hover span::after {
            transform: scaleX(1);
        }


/* clearfix */
.MegamenuNav ul:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.MegamenuNav ul {
    display: inline-block;
}

html[xmlns] .MegamenuNav ul {
    display: block;
}

* html .MegamenuNav ul {
    height: 1%;
}

#content {
    padding: 30px 0;
}

.noUnderLine:hover {
    text-decoration: none;
}

.hoverUserMenu {
    margin-top: -3px;
}

    .hoverUserMenu::after {
        position: relative;
        top: -11px;
    }

.noBorder {
    border: none !important;
}

ul {
    list-style-type: none !important;
}

li {
    list-style-type: none !important;
}


#InstallationLayoutParent .loadingParent {
    position: absolute;
    min-height: 40px;
    width: 70px;
    height: 60px;
    background: white;
    border-radius: 5px;
    margin: 15px;
    border: 1px solid #a99f9f;
    top: 42%;
    left: 43%;
    z-index: 9999;
}

.loadingParent {
    width: 100%;
    height: 100%;
    display: flex;
}

.loadingImg {
    display: block;
    width: 40px;
    margin: auto;
}

.MuiAppBar-colorDefault {
    background-color: transparent !important;
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
}

button:focus {
    outline: none;
}


.rowChildInfo {
    font-size: 12px;
    margin: 10px;
}

.generalInfo {
    padding: 0 5px;
}

.prodChild {
    background-color: white;
    margin: 5px;
    box-shadow: 2px 2px 10px #EDEDED;
    transition: all .2s ease-in-out;
}

.prodChildNoZoom {
    background-color: white;
    margin: 5px;
    box-shadow: 2px 2px 10px #EDEDED;
}


.canvasMaps {
    background: url('../assets/imgs/layoutImg.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: auto;
    height: 250px;
}

.drag-drop {
    padding: 2px;
    margin: 10px;
    color: white;
    touch-action: none;
    user-select: none;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
}

.obj1 {
    background-color: #BA3E00;
}

.obj2 {
    background-color: #79D686;
}

.productInfo {
    margin: 5px;
}

.loadingParent {
    width: 100%;
    height: 100%;
    display: flex;
}

.loadingImg {
    display: block;
    width: 40px;
    margin: auto;
}

.containerZoom {
    background-image: url(../assets/icons/search-24.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    cursor: pointer;
    height: 30px;
    margin-top: 18px;
    margin-left: 20px;
}

.carousel-indicators {
    position: relative;
}


.squareOf5 {
    float: left;
    position: relative;
    background-color: #ffffff;
    width: 18%;
    margin: 1%;
    overflow: hidden;
}

.squareOf4 {
    float: left;
    position: relative;
    background-color: #ffffff;
    margin: 10px;
    overflow: hidden;
}

.squareOf3 {
    float: left;
    position: relative;
    background-color: #ffffff;
    width: 30%;
    margin: 1.16%;
    overflow: hidden;
}

.squareOf2 {
    float: inherit;
    position: relative;
    background-color: #ffffff;
    width: 45%;
    margin: auto;
    align-content: center;
    overflow: hidden;
}

.squareOf1 {
    float: inherit;
    position: relative;
    background-color: #ffffff;
    width: 95%;
    align-Items: stretch;
    overflow: hidden;
}

    .squareOf1 div.table {
        align-items: stretch;
        height: 100%;
        margin: 0;
        padding: 15px 5px;
    }

        .squareOf1 div.table div {
            align-self: center;
        }

.Connected {
    width: 20px;
    height: 20px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #009845;
}

.Disconnected {
    width: 20px;
    height: 20px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #898A8D;
}

.Alarm {
    width: 20px;
    height: 20px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #E1251B;
}


.prodState {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0px;
    right: 24px;
    display: flex;
}

@media screen and (min-width: 1500px) {
    .megaListProduct {
        padding-left: 20%;
    }
}

.installationRow {
    margin-top: 10px;
    margin-bottom: 10px;
}

.roundImage {
    border-radius: 50%;
}

.menuIcon {
    height: 60px;
    width: 60px;
    border: 1px solid #e0e0e0;
}

.menuListItem {
    color: #9a9a9a !important;
}

.productDescriptionMenu {
    width: calc(100% - 50px);
    float: left;
}

.installationRow:hover {
    text-decoration: none;
}

.menuInstallationTitle > h4::after {
    display: block;
    content: '';
    border-bottom: solid 1px #7c7c7c;
    transform: scaleX(0);
    transition: transform 200ms ease-in-out;
    transform-origin: 0% 50%;
    margin-top: -2px;
}

.installationRow:hover > div .menuInstallationTitle > h4::after {
    transform: scaleX(1);
}

.disabledInstallationRow > .installationRow:hover > div .menuInstallationTitle > h4::after {
    transform: scaleX(0);
}

.disabledInstallationRow {
    cursor: default;
}

.disabledInstallationRow {
    opacity: 0.5
};

.menuProductTitle > h4::after {
    display: block;
    content: '';
    border-bottom: solid 1px #7c7c7c;
    transform: scaleX(0);
    transition: transform 200ms ease-in-out;
    transform-origin: 0% 50%;
    margin-top: -2px;
}

.productRow:hover > div .menuInstallationTitle > h4::after {
    transform: scaleX(1);
}

.noPadding {
    padding: 0 !important;
}

.imgSubmenuMegamenu {
    margin-top: 8px;
    height: 50px;
    width: 50px;
}

.productCell {
    overflow: hidden;
    margin: 5px 0;
    padding-right: 0;
    padding-top: 15px;
    padding-left: 15px;
}

    .productCell::after {
        content: '';
        width: 25px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: linear-gradient(90deg,transparent 0%, white 100%);
        z-index: 99
    }

.iconContainerMenu {
    width: 100%;
    height: 100%;
    padding: 5px 0 !important;
    background-color: #fff;
    transition: background-color 100ms ease-in-out;
}

    .iconContainerMenu:hover {
        background-color: #e4e4e4;
    }


.botHeader {
    width: 100%;
    margin: auto;
}

.botHeader div {
    margin: auto;
}

.botHeader li {
    color: white;
    margin: auto;
}

.navitem::after {
    display: block;
    content: '';
    border-bottom: solid 1px #7c7c7c;
    transform: scaleX(0);
    transition: transform 200ms ease-in-out, transform-origin 0s linear 200ms;
    transform-origin: 0% 50%;
}

.hover-menu::after {
    transform: scaleX(1);
}

.hover-menu:active::after {
    transition: transform 200ms ease-in-out;
    transform-origin: 50% 50%;
    transform: scaleX(0.75);
}

.userMenuContainer .dropdown-menu {
    width: 250px;
    align-items: stretch;
    margin-top: -0.1px;
}

.userMenuItem {
    padding: 10px 0 !important;
    height: 40px;
}

.userMenuItem:hover, .userMenuContainer .avatarZone > .logoutButton:hover {
    background-color: #e9ecef
}

.userMenuContainer .avatarZone {
    height: 60px !important;
    border-right: none !important;
    border-top: 1px solid #cccccc
}

.userMenuContainer .avatarZone > .MuiAvatar-circle,
.userMenuContainer .avatarZone > .MuiAvatar-circle > .MuiAvatar-img,
.userMenuContainer .avatarZone > .MuiAvatar-square,
.userMenuContainer .avatarZone > .userAvatar,
.userMenuContainer .avatarZone > .userAvatar > .MuiAvatar-img {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-right: none !important;
}

.userMenuContainer .avatarZone > .MuiAvatar-circle > .MuiAvatar-img,
.userMenuContainer .avatarZone > .userAvatar > .MuiAvatar-img {
    height: 100% !important;
    width: 100% !important;
}

.userMenuContainer .avatarZone > .logoutButton {
    height: 60px;
    width: 60px;
    border-right: none !important;
    border-left: 1px solid #cccccc
}

.userMenuContainer .avatarZone > .logoutButton {
    padding-top: 17px !important;
    color: #7c7c7c !important;
}

.userMenuContainer .avatarZone > .userName > p {
    display: table-cell;
    vertical-align: middle;
    color: #7c7c7c !important;
    height: 60px;
    padding: 0px 11px;
    word-break: break-word;
}

.dropdown-item {
    transition: all 100ms linear;
}

.dropdown-item:active {
    background-color: #e9ecef !important;
    color: black !important;
}

.userMenuItem .MuiBadge-root {
    margin-top: -13px
}

.headerFixed {
    /*position: fixed;
    
    width: 100%;*/
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1299;
    background: white;
}

.breadCrumb {
    /*position: fixed;
    top: 131px;*/
    background-color: white;
    z-index: 98;
    padding-bottom: 1px;
    min-height: 21px;
    /*box-shadow: 0 10px 8px -8px #8080803d;*/
    /*
    margin-left: -50vw;
    padding-left: 50vw;
    margin-right: -50vw;
    margin-left: -80%;
    padding-left: 80%;
    margin-right: -80%;
    width: 200%;
     */
    position: sticky;
    /*margin-top: -25px;*/
    top: 0px;
}

    .breadCrumb.notop {
        box-shadow: 0 5px 20px 0 rgb(0 0 0 / 7%);
        transition: box-shadow 0.3s ease-in-out;
    }

    .breadCrumb.top {
        box-shadow: none;
        transition: box-shadow 0.3s ease-in-out;
    }


    .breadCrumb > div {
        display: block;
    }


#badgeDropDown span {
    left: -20px;
}

#badgeRequestsReceived span {
    left: 223px;
}

#badgeInvitationsReceived span {
    left: 195px;
}

.dropdown-menu:hover a:first-child {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.dropdown-menu:hover a:last-child {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.dropdown-menu > div:first-child > div:first-child:hover {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.userMenuContainer .avatarZone > .logoutButton {
    border-bottom-right-radius: 5px !important;
}

div.menuIcon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
}
.linkGoTo{
    padding: 10px 0px !important;
}
.customBadge.bg-none .MuiBadge-colorSecondary {
    background-color: transparent;
}