.organizationView {
  :global {
    .container-organizationView {
      // border: 1px solid #d3d3d3;
      padding:0;

      .MuiPaper-root{
        padding:0;

        .TableContainer-root{
          padding: 0
        }
      }

      .MuiTableBody-root{
        a{
          display: contents;
        }
        tr{
          cursor: pointer;
          &:hover{
            background-color: #f7f7f7;
          }
        }
      }
    }
  }
}