.DNDView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  :global {
    .DND-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      overflow: hidden;

      &.blur {
        filter: blur(0.1rem);
        button {
          display: none;
        }
      }

      .mapProd {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
        cursor:pointer;

        &.dimmed {
          opacity: 0.5!important;
        }
      }

      .box-floorPlan {
        transition: box-shadow 250ms ease;
        border-radius: 10px;
        // &.editMode {
        //   box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.25) 20px 14px 24px;
        //   // box-shadow: inset rgba(201, 211, 219, 0.5) 0 0 0 2px, rgba(255, 255, 255, 0) 0 0 0 1px,
        //   //   rgba(201, 211, 219, 0.25) 20px 14px 24px;
        // }

        button {
          &.dragging {
            .stateDiv {
              cursor: grabbing !important;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
              transform: translateY(-3px) scale(1.03);
              z-index: 1000;
              border-radius: 50%;
              padding: 0;
              border: none;
            }
          }
        }
      }

      .controls {
        min-height: 36px;
        display: inline-flex;
        justify-content: flex-end;
        button {
          color: #757575 !important;
          min-width: 0px;
          &:hover {
            background-color: rgba(240, 240, 240, 1) !important;
          }
        }
      }

      .editMode-controls {
        display: inline-flex;
        justify-content: space-between;

        .btns {
          display: inline-flex;
          gap: 10px;
          label{
            color:#757575;
          }
          button {
            color: #757575 !important;
            min-width: 0px;
            &:hover {
              background-color: rgba(240, 240, 240, 1) !important;
            }
          }
        }
      }

      button {
        .product {
          width: 0;
          height: 0;
          cursor: default;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 50%;
          border: 1px solid #ddd;

          .stateDiv {
            &.editMode {
              cursor: grab;
            }
          }

          &.active {
            position: static; 
            transform: none; 
          }
        }

        &.dragging {
          .stateDiv {
            cursor: grabbing !important;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
            transform: translateY(-3px) scale(1.03);
            z-index: 1000;
            border-radius: 50%;
            padding: 0;
            border: none;
          }
        }
      }

      .box-imagen {
        flex: 1;
        position: relative; 
        width: 100%;
        height: 100%;
        min-height: 325px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain; 
          display: block;

          // old aspect ratio to preserve old coordinates
          @media (min-width: 1200px) {
            max-width: 470px;
            max-height: 313px;
          }
          @media (min-width: 992px) {
            max-width: 822px;
            max-height: 548px;
          }
          // *******
        }

        .stateDiv {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
          transform: scale(1);
          z-index: auto;
          transition: opacity 0.3s ease-in-out, box-shadow 0.2s ease, transform 0.2s ease;
          opacity: 1;
          border-radius: 50%;

          img {
            width: 24px;
            height: 24px;
            object-fit: contain;

            &.imgState {
              width: 100%;
              height: 100%;
            }
          }

          span {
            height: 11px;
            color: #fff;
            display: block;
            font-size: 9px;
            left: 50%;
            margin: 0;
            padding: 0;
            position: absolute;
            text-align: center;
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            width: 100%;
          }
        }
      }

      .box-unused {
        background-color: #f0f0f0; 
        padding: 10px; 
        width: 100%; 
        box-sizing: border-box; 
        border-radius: 5px;
        display: flex;
        align-items: center;

        > div {
          position: static;
          width: 100%;
          display: inline-flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 5px;
          min-height: 40px;
          button {
            position: static;
          }
        }

        .product {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
