.ProductsGeneralView {
  :global {
    .productsGeneralView-content {  
      margin-top: -10px;
      .v-hidden{
        // position: absolute;
        // top: -9999px;
        transform: scale(0);
        height: 0;
      }

      .summaryProduct {
        margin: 10px 0px;
        position: relative;
      }

      .generalInfo {
        .squareOf4 {
          margin: 15px 0 0 0;
          overflow: visible;
        }

        .generalProduct {
          > div {
            display: flex !important;
            justify-content: flex-end;
          }
        }
      }

      .dateSelector {
        .title {
          z-index: 9999;
          align-items: center;
          margin: 0;
          text-align: left;
        }

        .box-date {

          .in-box-date {
            position: sticky;
            top: 108px;
            background-color: #ffffff;
            z-index: 2;
            padding-left: 10px;
            display: inline-flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
          }

          .generalProduct {
            justify-content: right;
          }
        }
      }
    }
  }
}
