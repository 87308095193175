.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.containerFragments div{
    margin: 20px 0;
}

.rs-picker-menu {
    margin-left: -325px;
}

.flexContainer {
    display:flex;
    flex-wrap: wrap;
}

.flexContainerNoWrap {
    display:flex;
    justify-content: center;
    align-items: center;
}

.flexContainerNoWrap span {
    color: #7c7c7c !important;
    font-family: 'AktivGrotesk_Light', sans-serif !important;
    font-size: 14px !important;
    padding: 0px;
}

.flexContainerNoWrap label {
    margin-left:0px;
    gap: 10px;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}


.flexContainer span {
    color: #7c7c7c !important;
    font-family: "AktivGrotesk_Light", sans-serif !important;
    font-size: 14px !important;
    padding: 0px;
}

.flexContainer label{
    margin-left:0px;
    gap: 10px;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.card-container {
    box-shadow: 2px 2px 10px #ededed;
    padding: 0 10px;
}

.accordion-root-custom.MuiAccordionSummary-content {
    padding-left: 15px;
}
.MuiPaper-elevation1.accordion-root-custom {
    box-shadow: 2px 2px 10px #ededed;
    width: 99%;
    margin: 0 auto !important;
}

.accordion-root-custom .MuiAccordionSummary-root {
    margin-right: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    display: flex;
}
    .accordion-root-custom .MuiAccordionSummary-root .MuiAccordionSummary-content {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    .accordion-root-custom .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper, .accordion-root-custom .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper img {
        flex: 0 0 auto !important;
        width: auto;
        max-width: 100%;
        position: relative;
        margin-right: 15px;
        margin-left: 15px;
    }
    .underline {
        text-decoration: underline;
    }


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

th:focus {
    outline: none;
}

.react-bootstrap-table-sort-order>.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

th .dropup {
    position: absolute;
}
.react-bootstrap-table-sort-order {
    position: absolute;
}

th .dropup .caret, th .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.table-bordered thead th.sortable {
    cursor: pointer
}

.table-bordered thead th {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Chrome, Edge, Opera and Firefox */
}

.selectableRow {
    cursor: pointer;
    transition: 0.2s linear all;
}
.selectableRow:hover {
    background: #eeeeee
}

.container {
    margin-top: 25px;
}

html {
    overflow-y: auto;
    overflow-x: hidden;
}

body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0 !important;
    overflow: unset !important;
}

html input[disabled] {
    cursor: auto !important;
}

.MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid !important;
    border-bottom-color: #dfdfdf;
}




::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-thumb {
    background-image: initial;
    background-color: rgb(170, 170, 170);
}

::-webkit-scrollbar-thumb {
    width: 5px;
    background: rgb(170, 170, 170);
    border-radius: 6px;
    transition: all 200ms linear;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(130, 130, 130);
    background-color: rgb(130, 130, 130);
}

::-webkit-scrollbar-track {
    background-image: initial;
    background-color: rgb(200, 200, 200);
}

::-webkit-scrollbar-track {
    background: rgb(200, 200, 200);
}


.form-check {
    position: relative;
}

.form-check-input:focus {
    outline: none !important;
}

.form-check label {
    display: flex;
    position: relative;
    font-size: 20px;
    font-weight: 400;
    align-items: center;
    justify-content: start;
    cursor: pointer;
}

.form-check .form-check-input:disabled + label {
    border-color: #d4d4d4;
    cursor: auto;
}

.form-check label::before, .form-check label::after {
    pointer-events: none;
}

.form-check label::before {
    display: flex;
    content: ' ';
    height: 20px;
    width: 20px;
    border: solid 2px #7c7c7c;
    background: #fff;
    margin-right: 10px;
}

.form-check label::after {
    position: absolute;
    top: 6px;
    left: 4.5px;
    display: flex;
    content: ' ';
    height: 5px;
    width: 12px;
    border-bottom: solid 1px #007dba;
    border-left: solid 1px #007dba;
    background: none;
    transform: rotate(0deg) scale(0);
    transition: transform .3s ease, opacity .1s ease;
    transform-origin: center center;
}

.form-check .form-check-input:checked + label::after {
    opacity: 1;
    transform: rotate(-45deg) scale(.9)
}

.form-check .form-check-input:disabled + label::after {
    border-color: #9b9b9b;
}

.form-check .form-check-input:disabled + label::before {
    border-color: #9b9b9b;
}

.form-check-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
}

.edit-parent button:hover img {
    transition: all .2s ease-in 0s;
    filter: invert(1);
}

.edit-parent {
    display:flex;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    z-index: 1300;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px !important;
    }
}


.zoomContainer #drop {
    transform: scale(0.7);
}

.zoomContainer #modalId > div.col {
    padding: 0;
}

#locationContent #drop .prodInstallationManagment {
    margin: 0;
}

.zoomContainer #drop .prodInstallationManagment {
    margin: 0;
    width: inherit;
    height: inherit;
}

.zoomContainer .modal-header {
    border-bottom: none;
}

.zoomContainer .modal-footer {
    border-top: none;
}

.paramContainer > div {
    width: 100%
}

.paramContainer {
    padding-bottom: 10px;
}

.MuiSlider-thumb {
    background-color: currentColor !important;
    opacity: 0.7;
}

.custom-slider-label {
    position: absolute;
    right: 0;
    top: 2px;
    margin: 0 !important;
}

.MuiSlider-root {
    width: 100% !important
}

.sliderContainer > .MuiFormControl-root {
    width: 100%;
}

.MuiSlider-markActive, .MuiSlider-mark {
    display: none !important;
}

.MuiSlider-thumb.Mui-disabled {
    display: none;
}

.paramButton {
    width: 100% !important;
    margin: 0 !important;
    box-shadow: none !important;
}

.buttonScroll span {
    align-self: center;
}

.buttonScroll img {
    transition: all 0.4s ease-in-out 0s;
    filter: invert(0);
    margin: 0 1em;
}


.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    border-color: #7c7c7c !important;
    background-color: #7c7c7c !important;
    border-radius: 0;
}

.rs-calendar-table-cell-in-range:before {
    background-color: rgba(210,210,210,0.5) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    border: 1px solid black !important;
}

.rs-picker-toolbar-option {
    color: #7c7c7c !important;
    text-decoration: none;
}

    .rs-picker-menu .rs-picker-toolbar-option:focus, .rs-picker-toolbar-option:hover {
        color: black !important;
        text-decoration: none;
    }


.buttonScroll img {
    vertical-align: middle;
    border-style: none;
}

.buttonScroll .t2 {
    transition: color 0.4s ease-in-out 0s;
    line-height: 12px;
}

.buttonScroll a {
    transition: color 0.4s ease-in-out 0s;
    color: inherit;
    text-decoration: none;
}

.buttonScroll:hover {
    transition: all 0.4s ease-in-out 0s;
    background-color: #7c7c7c;
    color: white !important;
    border: solid 1px white;
}

    .buttonScroll:hover .t2 {
        color: white !important;
    }

    .buttonScroll:hover img {
        filter: brightness(2);
    }

.rs-picker-menu.placement-bottom-start.rs-picker-daterange-menu {
    right: 7em;
    bottom: 100px;
    top: initial !important;
    position: fixed;
    left: initial !important;
}

@media only screen and (min-width: 750px) {
    .calendar {
        /*bottom: 310px;*/
        visibility: visible !important;
    }

    .toFilters {
        /*bottom: 240px;*/
        visibility: visible !important;
    }

    .resset {
        /*bottom: 170px;*/
        visibility: visible !important;
    }

    .backToTop {
        /*bottom: 100px;*/
        visibility: visible !important;
    }
}

.granularity.MuiPaper-root {
    top: initial !important;
    bottom: 240px;
    left: initial !important;
    right: 6em;
}

div.MuiInputBase-root.granularityRoot {
    color: inherit;
    font-size: inherit;
    font-family: inherit;
}

.granularityRoot.MuiInputBase-root.MuiInput-root.MuiInput-underline:hover {
    color: white;
}

.granularityRoot.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
}

.granularityRoot .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    padding: 0;
    height: 50px;
    margin: 0px;
    width: 55px;
}

.granularityRoot .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input:hover {
    color: white !important;
}

.granularityRoot.MuiInput-underline:before {
    border-bottom: none !important;
}

.granularityRoot .MuiSvgIcon-root.MuiSelect-icon {
    display: none;
}

.MuiTabs-vertical {
    overflow: visible !important;
}

#button-tooltip {
    position: relative;
    z-index: 9999;
}

#badgeRequestsReceived span {
    left: 0px!important;
}
#badgeInvitationsReceived span {
    left: 0px !important;
}
.parentTabGrid .MuiTabs-flexContainer.MuiTabs-flexContainerVertical button:only-child {
    visibility: hidden;
}

.hiddeAnimation.isMap, .hiddeAnimation.notMap {
    opacity: 0;
}

.isMap.showAnimation, .notMap.showAnimation {
    left: 0;
   
}
.isMap {
    position: absolute;
    opacity: 1;
    left: 100%;
    transition: all .2s linear;
}
.notMap {
    position: absolute;
    opacity: 1;
    left: -100%;
    transition: all .2s linear;
}
.onlyOneTabChild .MuiTabs-root.MuiTabs-vertical {
    visibility: hidden;
}
.pointerEventNone{
    pointer-events:none;
}

.customSlider .MuiSlider-root {
    color: black;
    height: 4px;
    width: 300px;
    margin-bottom: 0;
}



.customSlider .MuiSlider-thumb {
    background-color: #fff !important;
    border: 1px solid currentColor;
    opacity: 1;
}



.customSlider .MuiSlider-thumb:focus, .customSlider .MuiSlider-thumb:hover, .customSlider .MuiSlider-thumn:active {
    box-shadow: inherit;
}



.customSlider .MuiSlider-markLabel {
    display: none;
}



.customSlider .MuiSlider-valueLabel {
    left: auto;
}



.customSlider .MuiSlider-track {
    height: 3px;
    border-radius: 4px;
}



.customSlider .MuiSlider-rail {
    height: 3px;
    border-radius: 4px;
    color: lightGray;
}

.date-container.calendar .react-datepicker-wrapper{
    width:auto;
}

.MuiToolbar-root.Toolbar-toolbar {
    border-bottom: none;
    margin-bottom:10px;
}
.table-custom-header-cell .MuiButtonBase-root.Mui-disabled{
    opacity:1;
}
.MuiTable-root.Table-table{
    min-width: 890px !important;
}
.TableContainer-root{
    overflow-x: auto;
}
.custom-grid .TableContainer-root {
    padding-left: 24px;
    padding-right: 24px;
}
.custom-grid .TableContainer-root > div{
    overflow: auto;
}
@media (max-width: 991.98px) {
    .MuiToolbar-root .SearchPanelInput-root {
        display: none;
    }
}
.MuiSlider-root .MuiSlider-thumb{
    height:15px;
    width: 15px;
}
.installationTree .rs-table-cell-expand-wrapper{
    width: 30px;
}
.react-select__menu-list{
    max-height:100px !important;
}
.jc-spaceBetween{
    justify-content:space-between !important;
}
.parameterFirmwareModal{
    width:600px;
}
.GWModal{
    width: 100%;
}
.responseDialogBackdrop {
    display: none;
}
.messageDialog{
    top:inherit !important; 
}
.container{
    overflow-x: visible;
}
.main-img-container{
    text-align:center;
}
.sparepart-category-detail-body .sparepart-item {
    padding: 5px 10px;
    border-bottom: 1px solid #9A9A9A;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 85px;
}
.sparepart-category-detail-body .sparepart-item .sparepart-info {
    padding-left: 15px;
    align-self: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 50%;
}
.sparepart-category-detail-body .sparepart-item .sparepart-detail .sparepart-detail-item .sparepart-item-index .spare-part-position {
    width: 27px;
    line-height: 27px;
    background-color: #000000;
    color: #FFFFFF;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
}
.sparepart-category-detail-body .sparepart-item .sparepart-detail {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-self: center;
}
.sparepart-category-detail-body .sparepart-item .sparepart-detail .sparepart-detail-item:last-child {
    border-bottom: none;
}
.sparepart-category-detail-body .sparepart-item .sparepart-detail .sparepart-detail-item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dee2e6;
}
.sparepart-category-detail-body .sparepart-item .sparepart-detail .sparepart-detail-item .sparepart-item-index {
    width: 10%;
    align-self: center;
    margin: 2px auto;
    display: block;
}
.sparepart-category-detail-body .sparepart-item .sparepart-detail .sparepart-detail-item .wrap-details {
    width: 85%;
}
.sparepart-category-detail-body .sparepart-item .sparepart-detail .sparepart-detail-item .wrap-details .details-body .specs {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sparepart-category-detail-body .sparepart-item .sparepart-detail .sparepart-detail-item .wrap-details .details-body .specs p {
    margin-bottom: 0px;
}
.wrap-details .details-body {
    padding: 1em 3em;
}
.boxSpareParts .sparepart-category-detail-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

#breadcrumbId.bhidden{
    display: none;
}
.notop #breadcrumbId.bhidden{
    display:block;
}

.centeredButtonText {
    width: 100px !important;
    min-width: 100px !important;
    height: 30px !important;
    min-height: 30px !important;
    padding-top: 3px !important;
    padding-bottom: 0px !important;
}

.noDataAvailable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dictionaryContainer {
    width: 100%; 
    height: 100%;
    /* margin-top: 15px; */
}