.ModalProdLocationZoomView {
  :global {
    .modalProdLocationZoomView-content {
      .modalZoomLocation {
        width: 300px;
        height: 100%;

        .noData-box{
          margin: auto;
          top: 34%;
          position: relative;
        }

        .col {
          padding: 0;
          .ZoomProdModalChild {
            justify-content: center;
            display: flex;

            .box-modal {
              position: relative;
              margin: 12px;

              .inbox-modal {
                width: 300px;
                height: 200px;

                .dropzone {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
