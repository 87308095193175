.PowerBIView {
  :global {
    .powerBI-content {
      background-color: #f9f9f9;

      .box-button {
        width: 120px;
        position: absolute;
        top: 0;
        display: inline-flex;
        gap: 10px;
        justify-content: space-evenly;
        z-index: 9999;
        height: 44px;
        align-items: center;

        button {
          border: 1px solid #575757;
          border-radius: 3px;
          background-color: white;
          transition: all 0.2s ease-in;

          svg {
            max-width: 30px;
            padding: 0;
            max-height: 30px;
          }

          &:hover {
            background-color: #dddddd;
          }
        }
      }

      .reportClass {
        iframe {
          height: 99.5vh !important;
          z-index: 9;
          background-color: white;
        }
      }
    }
  }
}
