.userUnitsView {
  :global {
    .container-userUnitsView {
      margin-top: 3rem;
      .table {
        max-width: 600px;
        margin: 5px;
        box-shadow: 2px 2px 10px #ededed;

        tr.title-table {
          th.label-box,
          th.select-box {
            border-top: 0 !important;
            color: #666666;
            font-family: 'AktivGrotesk_Bold', sans-serif;
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
          }
        }
        th {
          padding: 0.75rem 1.5rem !important;
          // &.select-box {
          //   text-align: center;
          //   // >div{
          //   //   float: right;
          //   // }
          // }
          &.label-box {
            text-align: left;
            vertical-align: bottom;
            color: #7c7c7c;
            font-family: 'AktivGrotesk_Light', sans-serif;
            font-size: 14px;
            font-weight: 100;
          }
        }

        .MuiSelect-select {
          min-width: 150px;
        }
      }
    }
  }
}
