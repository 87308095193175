.EmptyBarChartView {
  height: 100%;
  :global {
    .container-EmptyBarChartView {
      position: relative;
      width: 100%;
      height: 100%;
      filter: grayscale(1);
      pointer-events: none;
      // height: 500px;
      // margin-top: 30px;

      .chart-group {
        background-color: #fbfbfb;
      }

      .box-chart {
        position: relative;
        filter: blur(1.5px);
        background-color: #fbfbfb;
        height: 100%;
      }
      .box-error {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #00000069;
        color: white;
        padding: 10px;
        border-radius: 10px;
        z-index: 9;

        .error-text {
          margin: 0 !important;
        }
      }
    }
  }
}
