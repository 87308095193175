.Registration {
  :global {
    .register {
      font-family: Segoe UI, SegoeUI, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    }
    .register .registerContainer {
      text-align: center;
      width: 100%;
      display: table;
      height: 100vh;
      padding: 0px;
      margin-right: auto;
      margin-left: auto;
    }

    .register .registerRow {
      width: 100%;
      margin: 0;
      display: table-cell;
      vertical-align: middle;
    }

    .register .unified_container .registerRow .panel-default {
      padding: 40px 30px;
      margin-top: auto;
      margin-bottom: auto;
      height: 100%;
      background-color: white;
    }

    .register .panel {
      border: 1px solid transparent;
      border-radius: 4px;
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0,0.5);
      box-shadow: 0 1px 1px rgba(0, 0, 0,0.5);
      border-color: #ddd;
    }

    .unified_container .registerRow .panel-default .panel-body {
      max-width: 380px;
      width: auto;
      margin: 0 auto;
      padding: 0px;
    }

    .register .register-col-lg-6 {
      max-width: 470px;
      width: 100%;
      float: none;
      display: inline-block;
      vertical-align: top;
      padding: 0px;
    }

    .unified_container .registerRow .panel-default .panel-body .companyLogo {
      display: block;
    }

    .unified_container .registerRow .panel-default .panel-body img {
      max-width: 100%;
      width: auto;
      height: 50px;
      margin: 0 auto;
      display: block;
    }

    .register img {
      vertical-align: middle;
    }

    .register img {
      border: 0;
    }

    .unified_container .registerRow .panel-default #api {
      display: table;
      margin: 0;
      width: 100%;
    }

    .unified_container .registerRow .panel-default #api .localAccount {
      top: 10px;
      width: 100%;
    }

    .unified_container .registerRow .panel-default #api .intro {
      margin-top: 20px;
      margin-bottom: 5px;
      display: block;
      text-align: center;
    }

    .unified_container
      .registerRow
      .panel-default
      #api
      .localAccount
      .intro
      .h2 {
      font-size: 20px !important;
    }

    .confirmRegister .h2,
    .confirmRegister .t3,
    .confirmRegister #api .intro {
      text-align: center !important;
    }

    .register .register-col-lg-6 {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }

    .register .registerContainer:after,
    .register .registerContainer:before,
    .register .panel-body:after,
    .register .panel-body:before,
    .register .registerRow:after,
    .register .registerRow:before {
      display: table;
      content: " ";
    }

    .unified_container
      .registerRow
      .panel-default
      #api
      .localAccount
      .entry
      .entry-item {
      width: 100%;
      padding-top: 6px;
      padding-bottom: 6px;
      display: block;
    }

    .unified_container
      .registerRow
      .panel-default
      #api
      .localAccount
      .entry
      .entry-item
      input {
      outline: none;
      background-color: #fff !important;
      background-image: none !important;
      /* border-radius: 0.5rem !important; */
      box-shadow: 0px 2px 10px rgba(0, 0, 150 ,0.15);
      color: #555;
      display: inline-block;
      font-size: 1em;
      height: 50px;
      line-height: 1.42857143;
      padding: 6px 20px;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !important;
      width: 100% !important;
      -o-transition: border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s !important;
      -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s !important;
      border: 1px solid white;
    }

    .unified_container
      .registerRow
      .panel-default
      #api
      .localAccount
      .entry
      .buttons {
      margin-top: 2rem;
    }

    .unified_container
      .registerRow
      .panel-default
      #api
      .localAccount
      .entry
      .buttons
      button {
      background-image: none;
      background-color: #898989;
      border: none;
      /* border-radius: 0.5rem; */
      cursor: pointer;
      display: inline-block;
      font-size: 1em;
      font-weight: 400;
      height: inherit;
      line-height: 1.3333333;
      margin-right: 0px;
      margin-bottom: 1.2rem;
      margin-left: 0px;
      padding: 10px 16px;
      text-align: center;
      touch-action: manipulation;
      user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      width: inherit;
      -moz-user-select: none;
      -ms-touch-action: manipulation;
      -ms-user-select: none;
      -webkit-user-select: none;
      color: #fff;
      width: 63%;
      box-shadow: 0 0px 30px 0px #dae1f7;
    }
  }
}
