.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.18);
    background-color: #fff;
    width: 300px;
    height: 125px;
    transition: 0.3s;
    margin: 15px 0 15px 25px;
    border: none;
}

.noBoxShadow {
    box-shadow: none !important;
}

.card:hover {
    box-shadow: 0 6px 12px 0 rgba(0,0,0,0.19);
}

.notifications-group {
    margin-right: -24px;
    margin-left: -24px;
}



.alert-card-container {
    padding: 10px;
    height: 100%;
}

.alert-card-container .icon-col {
    display: table;
    height:100%;
    margin-right: 25px;
    margin-left: 10px;
}

.alert-card-container .icon-col .alarmState {
    display: table-cell;
    vertical-align: middle;
}

.alert-card-container .alert-card-content {
    width: 183px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.alarmState > .stateDiv > div {
    width: 60px;
    height: 60px;
    position: relative;
}

.alarmState > .stateDiv div img:nth-child(1).state-noAlert {
    content: url("https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/14_status_online.svg");
}

.alarmState > .stateDiv div img:nth-child(1).state-alert {
    content: url("https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/14_status_alert.svg");
}

.alarmState > .stateDiv div img:nth-child(1).state-rule {
    content: url("https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/14_status_rule.svg");
}

.alarmState > .stateDiv div img:nth-child(1).state-warning {
    content: url("https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/14_status_warning.svg");
}

.alarmState > .stateDiv div svg {
    width: 24px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -12px;
}

.alarmState > .stateDiv div span {
    display: block;
    color: white;
    width: 100%;
    font-size: 9px;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
}

.show-history-btn {
    position:absolute;
    right: 24px;
}

.edit-rules-btn {
    position: absolute;
    right: 130px;
}

.react-multiple-carousel__arrow--left {
    left: -15px !important;
}

.react-multiple-carousel__arrow--right {
    right: -15px !important;
}

.react-multiple-carousel__arrow {
    background: transparent !important;
}

.react-multiple-carousel__arrow::before {
    transition: font-size 150ms linear;
    font-size: 22px;
    color: inherit !important;
}

.react-multiple-carousel__arrow:hover::before {
    font-size: 25px;
}

.closeIcon {
    position: absolute !important;
    left: 15px;
    top: 15px;
}

.closeAlertButton {
    position: absolute !important;
    top: 0;
    right: 0;
}

.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.disabledCard {
    background-color: #eeeeee;
    pointer-events: none;
}

.disabledCard .form-check .form-check-input + label {
    border-color: #d4d4d4;
    cursor: auto;
}

.disabledCard .form-check .form-check-input + label::after {
    border-color: #d4d4d4;
}

.disabledCard .form-check .form-check-input + label::before {
    border-color: #d4d4d4;
}

.disabledCard .MuiIconButton-root {
    color: rgba(0, 0, 0, 0.34);
}

.alertModel {
    max-width: 800px
}

.createRuleButton {
    position: absolute;
    right: 33px;
    top: 20px;
}

.ruleModal #divForm {
    margin-top: 70px;
}
.ruleModal h2 {
    text-transform: capitalize;
}
.ruleModal label {
    font-size: 14px !important;
    color: black;
    padding-bottom: 10px;
}