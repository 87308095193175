.LandingView {
  :global {
    .landing-content {
      min-height: '100vh';

      // Fade in (optional)
      opacity: 0;
      animation: fadeIn 0.15s ease-in forwards;
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      //*******

      &.roca {
        @extend %roca;
      }

      &.laufen {
        @extend %laufen;
      }
    }
  }

  %roca {
    .headerFixed {
      background: black;
      color: white;

      .loginBtn {
        opacity: 0;
        animation: fadeIn 0.15s ease-in forwards;

        color: #ffffff;
        background-color: black;
        border: 1px solid black;
        font-family: Roboto-Bold, Helvetica, Arial, sans-serif;
        min-width: 150px;
        min-height: 35px;
        /* height: 45px; */
        margin: 10px;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 15px;
        padding: 0.375rem 0.75rem;
        text-transform: uppercase;
        box-shadow: none !important;
        border-radius: 0;
        transition: all 0.2s linear;

        &:hover {
          background-color: #222;
          border-color: #222;
        }
      }

      .headerSpace {
        padding-left: 60px;
        height: 90px;
      }
    }

    .main {
      background-image: url('https://strgpiotcoredev.blob.core.windows.net/web-html/roca/LandingPage.jpg');
      min-height: 100vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
    }

    .footerNav {
      color: white;
      background-color: black;
      letter-spacing: 1px;
      font-family: Roboto-Bold, Helvetica, Arial, sans-serif !important;
      font-size: 11px;
    }
  }

  %laufen {
    background-color: white;

    .headerFixed {
      background: white;

      .loginBtn {
        opacity: 0;
        animation: fadeIn 0.15s ease-in forwards;

        border: 1px solid #7c7c7c !important;
        background-color: white !important;
        width: 150px;
        height: 45px;
        margin: 10px;
        text-transform: uppercase;
        border-radius: 0;
        color: #7c7c7c !important;
        font-family: 'AktivGrotesk_Bold', sans-serif;
        font-size: 11px;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 18px;
        padding: 0.375rem 0.75rem;
        box-shadow: none !important;

        &:hover {
          transition: all 0.2s ease-in 0s;
          border: 1px solid black;
          color: white !important;
          background-color: #7c7c7c !important;
        }

        .headerSpace {
          padding-left: 60px;
          height: 90px;
        }
      }
    }

    .main {
      background-image: url('https://strgpiotcoredev.blob.core.windows.net/web-html/laufen/LandingPage.jpg');
      min-height: 100vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
    }

    .footerNav {
      color: #9a9a9a;
      background-color: #d5d5d5;
      letter-spacing: 1px;
      font-family: 'AktivGrotesk_Bold', sans-serif !important;
      font-size: 11px;
    }
  }
}