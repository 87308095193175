.ProductsSummaryView {
  :global {
    .productsSummaryView-content {
      
      .prodInfo {
        .inbox {
          align-items: stretch;
          padding-bottom: 10px;
        }
        .prodInfo-img {
          max-width: 100%;
          max-height: 200px;
          margin: 0 auto;
          display: block;
        }
      }

      .goToGw-img {
        height: 30px;
      }

      .edit-parent {
        align-items: center;
      }

      .summaryInfo {
        height: 100%;
        .dimensions {
          margin: 0;
          align-self: top;

          .h4Dimensions {
            overflow-wrap: anywhere;
          }
        }
      }

      .dimensions-installation {
        align-self: top;
        margin-top: 6px;
      }

      .dimensions-tagId {
        line-height: 18px;
      }

      .dimensions-box {
        align-self: top;
      }

      .custom-button-span {
        white-space: nowrap;
      }

      .DrawTechDocs-img {
        width: 24px;
      }

      .atributDesc {
        cursor: pointer;
        margin-left: 5px;
      }

      .pin-box {
        display: flex;

        .title-pin {
          display: flex;
          align-items: center;
          margin-right: 5px;
        }

        .text-pin {
          margin-bottom: 0;
          padding: 0 5px;
        }

        .inside-pin-box {
          cursor: pointer;
        }
      }
    }
  }
}
