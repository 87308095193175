@keyframes bg {
    0% {
        background-size: 0 4px, 4px 0, 0 4px, 4px 0;
    }

    25% {
        background-size: 100% 4px, 4px 0, 0 4px, 4px 0;
    }

    50% {
        background-size: 100% 4px, 4px 100%, 0 3px, 4px 0;
    }

    75% {
        background-size: 100% 4px, 4px 100%, 100% 4px, 4px 0;
    }

    100% {
        background-size: 100% 4px, 4px 100%, 100% 4px, 4px 100%;
    }
}

div.border-animation {
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, #999999 100%, #999999 100%), linear-gradient(to bottom, #999999 100%, #999999 100%), linear-gradient(to right, #999999 100%, #999999 100%), linear-gradient(to bottom, #999999 100%, #999999 100%);
    background-size: 100% 4px, 4px 100%, 100% 4px, 4px 100%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    animation: bg 60s linear 1;
    animation-play-state: running;
    /*animation-iteration-count: infinite;*/
}

div.button-refreshing {
    background-color: #bbbbbb;
}
#refresh-icon {
    transition: none;
    transform: rotate(0deg);
}
div.button-refreshing #refresh-icon {
    transition: ease-in-out 0.5s all;
    transform: rotate(360deg);
}

div.border-animation:hover {
    animation-play-state: initial;
}

div.border-animation .refresh-icon {
}
