
.product-list .product-card__wrapper .wrapper-producto {
    box-shadow: none;
    padding-bottom: 0;
}

.wrapper-producto {
    
    background-color: #fff;
}

.wrapper-producto .wrapper-img {
    width: 100%;
    height: 0;
    padding-bottom: calc(360 / 354 * 70%);
    position: relative;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.portlet a:hover, .portlet a:focus, .portlet a:active {
    text-decoration: none;
    color: inherit;
}

.portlet a {
    color: inherit;
    text-decoration: none;
}


.search-box .product-list .wrapper-producto .wrapper-text {
    padding: 20px 20px;
}

.wrapper-producto .wrapper-text {
    padding: 30px 15px;
    overflow: hidden;
}

a {
    cursor: pointer;
}

a:focus {
    text-decoration: none;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.wrapper-producto .wrapper-text .coleccion a {
    color: #00538b;
}

.search-box .product-list .wrapper-producto .wrapper-text .title-producto {
    margin-bottom: 20px;
}

.wrapper-producto .wrapper-text .title-producto {
    font-size: 15px;
    line-height: 21px;
    letter-spacing: normal;
    font-weight: 300;
    margin-bottom: 30px;
    min-height: 40px;
    align-self: center;
}

.wrapper-producto .wrapper-text .title-producto a {
    display: block;
}

.wrapper-producto .code-ref {
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 15px;
    color: #000;
    margin: 0;
    display: inline-block;
}

.wrapper-producto .wrapper-text .dades {
    display: flex;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 15px;
    clear: both;
}

.search-box .product-list .wrapper-producto .wrapper-text .dades .small {
    width: 100%;
    float: none;
    text-align: left;
    margin-bottom: 0;
}


.wrapper-producto .wrapper-text .dades .small {
    width: 50%;
    display: inline-block;
    text-align: right;
    white-space: nowrap;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;
    margin-left: auto;
}

.wrapper-producto .wrapper-text .dades .small span {
    line-height: 21px;
    letter-spacing: normal;
}


.wrapper-producto .wrapper-text .dades .small span sub {
    font-size: 11px;
    line-height: 21px;
    letter-spacing: normal;
    bottom: 0;
}


.search-box .product-list {
    margin-bottom: 100px;
    box-shadow: none;
}

.search-box .product-list .product-list__header {
    position: relative;
    margin-bottom: 30px;
}


@media (max-width: 991px) {
    .search-box #category {
        z-index: 105;
    }
}


@media (max-width: 767px) {
    .collapse-mobile {
        position: fixed;
        top: 0;
        left: -200%;
        background: white;
        min-width: 90%;
        height: 100%;
        padding: 20px;
        overflow: auto;
        z-index: 9;
        -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.1);
        -moz-box-shadow: 0 0 50px 0 rgba(0,0,0,0.1);
        box-shadow: 0 0 50px 0 rgba(0,0,0,0.1);
        transition: all .2s;
    }

    .advanced-search {
        margin: 0 -12px 30px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
    }

        .advanced-search p {
            text-transform: uppercase;
            margin-bottom: 0;
            font-family: 'Roboto-Bold',Helvetica,Arial,sans-serif;
            font-size: 11px;
            letter-spacing: 1px;
            line-height: 15px;
        }

            .advanced-search p a {
                padding: 15px;
                display: inline-block;
            }

    .collapse-mobile.active {
        left: 0;
        transition: all .2s;
    }

    .search-box #category {
        z-index: 105;
    }

    .search-filter .arrow {
        letter-spacing: 0;
    }

    .collapse-mobile .prev .arrow:before {
        content: '';
        background: url(https://www.roca.es/o/roca-theme/images/icons/chevron_left-24.svg) no-repeat center center;
        height: 11px;
        width: 11px;
        margin-right: 10px;
        background-size: cover;
        display: inline-block;
        position: relative;
        top: 2px;
    }
}





.search-filter {
    padding-right: 20px;
}

.search-filter ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.search-filter .category li {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 28px;
    padding-bottom: 20px;
}

.search-filter .category li a.active {
    padding-bottom: 2px;
}

.search-filter .category li a {
    padding-bottom: 5px;
    position: relative;
}

.search-filter .category li a.active:before {
    width: 100% !important;
    background-color: #000 !important;
}

.search-filter .category li a:before {
    content: "";
    height: 1.2px;
    width: 0;
    position: absolute;
    bottom: 8px;
    left: 0;
    /* background-color: #000; */
    background-color: white;
    -webkit-transition: width .3s cubic-bezier(0,1,0.66,1);
    transition: width .3s cubic-bezier(0,1,0.66,1);
    height: 1px;
    bottom: -2px;
}

.search-filter ul li span {
    font-size: 12px;
    line-height: 18px;
}

.search-filter .category li .subcategory {
    padding: 19px 0 6px;
}

.collapse.show {
    display: block;
}

.search-filter ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.collapse {
    display: none;
}

.search-filter .category li .subcategory li {
    font-size: 10px;
    letter-spacing: 1px;
    line-height: 18px;
    letter-spacing: 1px;
    padding-bottom: 14px;
}

.search-filter .category li .subcategory li a {
    border-bottom: 0;
    padding-bottom: 0;
    position: relative;
}

.search-filter ul li span {
    font-size: 12px;
    line-height: 18px;
}

.search-filter .category li .subcategory li a:hover:before {
    width: 100%;
}

.search-filter .category li .subcategory li a:before {
    content: "";
    height: 1.2px;
    width: 0;
    position: absolute;
    bottom: 8px;
    left: 0;
    background-color: #000;
    -webkit-transition: width .3s cubic-bezier(0,1,0.66,1);
    transition: width .3s cubic-bezier(0,1,0.66,1);
    height: 1px;
    bottom: -2px;
}

.search-filter .category li a:hover:before {
    width: 100%;
}

.search-filter .category li a:before {
    content: "";
    height: 1.2px;
    width: 0;
    position: absolute;
    bottom: 8px;
    left: 0;
    background-color: #000;
    -webkit-transition: width .3s cubic-bezier(0,1,0.66,1);
    transition: width .3s cubic-bezier(0,1,0.66,1);
    height: 1px;
    bottom: -2px;
}


.search-box {
    margin-bottom: 42px;
}

.search-box .close-back {
    text-align: right;
    margin-top: 40px;
}

.search-box input.search-box-input {
    width: 100%;
    max-width: 520px;
    text-align: center;
    border: 0;
    color: #000;
    margin: 0 auto 20px;
    display: block;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: normal;
}

button, input {
    overflow: visible;
}

.search-box input *:not(.form-check-input) {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: textfield;
    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}
.efect-gray {
    background-color: #ededed;
}
.wrapper-producto .form-check label::before{
    margin-right:0;
}
