.ModalAddPackageView {
  :global {
    .close-btn {
      display: inline-flex !important;
      -webkit-box-align: center !important;
      align-items: center !important;
      -webkit-box-pack: center !important;
      justify-content: center !important;
      position: relative !important;
      box-sizing: border-box !important;
      -webkit-tap-highlight-color: transparent !important;
      background-color: transparent !important;
      outline: 0px !important;
      border: 0px !important;
      margin: 0px !important;
      cursor: pointer !important;
      user-select: none !important;
      vertical-align: middle !important;
      appearance: none !important;
      text-decoration: none !important;
      text-align: center !important;
      flex: 0 0 auto !important;
      font-size: 1.5rem !important;
      padding: 8px !important;
      border-radius: 50% !important;
      overflow: visible !important;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
      color: rgb(0, 83, 139) !important;

      &:hover {
        background-color: rgba(0, 83, 139, 0.04) !important;
      }
    }

    .react-select-container{
      .react-select__control{
        min-height: 40px;
        &:hover{
          border-color: black;
        } 
      }
    }

    .ModalAddPackageStep1View {

      .checkStep1 {
        input {
          margin-top: 0px;
          margin-left: 0;
          z-index: 9;
          cursor: pointer;
        }
      }

      .tooltip-custom {
        display: inline-flex;
        align-items: center;
        width: 100%;
        .help-icon {
          width: 20px;
          margin-top: -6px;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }

    .ModalAddPackageStep2View {
      .optional-box{
        border-top:1px solid #cccccc;
        margin-top: 35px;
      }
    }


    button {
      &.disabled-mode {
        background-color: #80808014 !important;
        filter: invert(1) !important;
        border-color: transparent !important;
        pointer-events: none !important;
        // transition: all 0.2s linear;
      }
    }
  }
}
